<template>
  <div id="app">
    <NavBar v-if="!path"></NavBar>
    <div ref="visual" :class="{ main: !path }">
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view />
      </keep-alive>
      <router-view v-else />
    </div>
    <BackTop v-if="!path"></BackTop>
    <Footer v-if="!path"></Footer>
  </div>
</template>

<script>
import BackTop from '@/components/backtop/backtop'
import NavBar from '@/components/navbar/navbar.vue'
import Footer from '@/components/footer/footer.vue'
export default {
  components: { NavBar, Footer, BackTop },
  data() {
    return {
      path: ''
    }
  },

  watch: {
    $route(val) {
      if (val.path == '/manage-login') return (this.path = val.path), (this.$refs.visual.style.marginTop = '0px')
      this.path = ''
      if (val.meta.iseat) {
        this.$refs.visual.style.marginTop = '200px'
      } else {
        this.$refs.visual.style.marginTop = '130px'
      }
    }
  }
}
</script>

<style lang="less">
@import url('~@/assets/css/global.less');
</style>
