import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const userInfo = localStorage.getItem('user-info')
export default new Vuex.Store({
  state: {
    userInfo: userInfo ? JSON.parse(userInfo) : '',
    teamForm: {},
    payPopup: false,
    payStatus: 0,
    config: ''
  },
  mutations: {},
  actions: {},
  modules: {}
})
