import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}

const routes = [
  {
    path: '/',
    component: () => import('@/views/home/index.vue'),
    meta: {
      name: '首页',
      navBarIndex: 0,
      iseat: false,
      keepAlive: true
    }
  },
  {
    path: '/match',
    redirect: '/match-list',
    component: () => import('@/views/match/index.vue'),
    meta: {
      name: '赛事',
      navBarIndex: 1
    },
    children: [
      {
        path: '/match-list',
        component: () => import('@/views/match/children/list.vue'),
        meta: {
          name: '赛事列表',
          navBarIndex: 1,
          iseat: false,
          keepAlive: true
        }
      },
      {
        path: '/match-detail',
        component: () => import('@/views/match/children/detail.vue'),
        meta: {
          name: '赛事详情',
          navBarIndex: 1,
          iseat: true,
          keepAlive: true
        }
      },
      {
        path: '/match-entry',
        name: 'match-entry',
        component: () => import('@/views/match/children/entry.vue'),
        meta: {
          name: '赛事报名',
          navBarIndex: 1,
          iseat: true,
          keepAlive: false
        }
      },
      {
        path: '/match-query',
        component: () => import('@/views/match/children/query.vue'),
        meta: {
          name: '报名查询',
          navBarIndex: 1,
          iseat: true,
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/activity',
    redirect: '/activity-list',
    component: () => import('@/views/activity/index.vue'),
    meta: {
      name: '活动',
      navBarIndex: 2
    },
    children: [
      {
        path: '/activity-list',
        component: () => import('@/views/activity/children/list.vue'),
        meta: {
          name: '活动列表',
          navBarIndex: 2,
          iseat: false,
          keepAlive: true
        }
      },
      {
        path: '/activity-detail',
        component: () => import('@/views/activity/children/detail.vue'),
        meta: {
          name: '活动详情',
          navBarIndex: 2,
          iseat: true,
          keepAlive: true
        }
      },
      {
        path: '/activity-entry',
        component: () => import('@/views/activity/children/entry.vue'),
        meta: {
          name: '活动报名',
          navBarIndex: 2,
          iseat: true,
          keepAlive: false
        }
      }
    ]
  },
  {
    path: '/score',
    redirect: '/score-list',
    component: () => import('@/views/score/index.vue'),
    meta: {
      name: '成绩',
      navBarIndex: 3
    },
    children: [
      {
        path: '/score-list',
        component: () => import('@/views/score/children/list.vue'),
        meta: {
          name: '成绩列表',
          navBarIndex: 3,
          iseat: false,
          keepAlive: true
        }
      },
      {
        path: '/score-query',
        component: () => import('@/views/score/children/query.vue'),
        meta: {
          name: '赛事查询',
          navBarIndex: 3,
          iseat: true,
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/news',
    redirect: '/news-list',
    component: () => import('@/views/score/index.vue'),
    meta: {
      name: '资讯',
      navBarIndex: 4
    },
    children: [
      {
        path: '/news-list',
        component: () => import('@/views/news/children/list.vue'),
        meta: {
          name: '资讯列表',
          navBarIndex: 3,
          iseat: false,
          keepAlive: true
        }
      },
      {
        path: '/news-detail',
        component: () => import('@/views/news/children/detail.vue'),
        meta: {
          name: '资讯详情',
          navBarIndex: 3,
          iseat: true,
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/mine',
    redirect: '/mine-match',
    component: () => import('@/views/mine/index.vue'),
    meta: {
      name: '我的',
      navBarIndex: -1
    },
    children: [
      {
        path: '/mine-match',
        component: () => import('@/views/mine/children/match.vue'),
        meta: {
          name: '我的赛事',
          navBarIndex: -1,
          asideIndex: 0,
          iseat: false,
          keepAlive: false
        }
      },
      {
        path: '/mine-activity',
        component: () => import('@/views/mine/children/activity.vue'),
        meta: {
          name: '我的活动',
          navBarIndex: -1,
          asideIndex: 1,
          iseat: false,
          keepAlive: false
        }
      },
      {
        path: '/mine-info',
        component: () => import('@/views/mine/children/info.vue'),
        meta: {
          name: '个人信息',
          navBarIndex: -1,
          asideIndex: 2,
          iseat: false,
          keepAlive: false
        }
      },
      {
        path: '/mine-alter',
        component: () => import('@/views/mine/children/alter.vue'),
        meta: {
          name: '修改密码',
          navBarIndex: -1,
          asideIndex: 3,
          iseat: false,
          keepAlive: false
        }
      }
    ]
  },
  {
    path: '/account',
    redirect: '/account-login',
    component: () => import('@/views/account/index.vue'),
    meta: {
      name: '账户',
      navBarIndex: -1
    },
    children: [
      {
        path: '/account-login',
        component: () => import('@/views/account/children/login.vue'),
        meta: {
          name: '登录',
          navBarIndex: -1,
          keepAlive: false
        }
      },
      {
        path: '/account-register',
        component: () => import('@/views/account/children/register.vue'),
        meta: {
          name: '注册',
          navBarIndex: -1,
          keepAlive: false
        }
      },
      {
        path: '/account-registers',
        component: () => import('@/views/account/children/registers.vue'),
        meta: {
          name: '注册',
          navBarIndex: -1,
          keepAlive: false
        }
      }
    ]
  },
  {
    path: '/manage',
    redirect: '/manage-login',
    component: () => import('@/views/manage/index.vue'),
    meta: {
      name: '账户',
      navBarIndex: -1
    },
    children: [
      {
        path: '/manage-login',
        component: () => import('@/views/manage/children/login.vue'),
        meta: {
          name: '登录',
          navBarIndex: -1,
          keepAlive: false
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior,
  // mode: 'history',
  base: process.env.BASE_URL
})

export default router
