<template>
  <div class="footer" v-if="info">
    <div class="top">
      <div class="content flex_space_between">
        <div class="left flex_direction_column">
          <div class="logo flex">
            <img :src="$store.state.config.logo" />
            <div class="name">江苏省田径赛事活动信息平台</div>
          </div>

          <div class="link flex">
            <span>友情链接：</span>
            <a :href="item.url" target="_blank" v-for="item in shipList" :key="item.id">{{ item.name }}</a>
          </div>
        </div>
        <div class="center flex_direction_column">
          <div class="center_top flex">
            <img src="@/assets/image/img/10.png" />
            <p>{{ info.phone }}</p>
          </div>
          <span> 邮箱： {{ info.mailbox }}</span>
          <span> 地址： {{ info.address }}</span>
        </div>
        <div class="right flex">
          <div class="box" v-for="(item, index) in info.qr_code" :key="index">
            <img :src="item.src" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom flex_center">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >© {{ info.copyright }} ICP经营许可证：{{ info.record_no }} / {{ info.support }}</a
      >
    </div>
  </div>
</template>

<script>
import { getConfig, friendship } from '@/service/api/index'
export default {
  data() {
    return {
      info: '',
      shipList: []
    }
  },

  mounted() {
    this.getConfig()
  },

  methods: {
    async getConfig() {
      const res = await getConfig()
      this.info = res.msg
      this.$store.state.config = res.msg
      console.log(this.info)
      this.friendship()
    },
    async friendship() {
      const res = await friendship()
      this.shipList = res.msg
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: @color_two;
  .top {
    border-top: 4px solid @color_one;
    .content {
      padding: 50px 0 32px 0;
      .left {
        align-items: flex-start;
        height: 155px;
        .logo {
          img {
            width: 70px;
            height: 70px;
            margin-right: 10px;
          }
          .name {
            color: #dde2ee;
            font-size: 22px;
          }
        }

        .link {
          margin-top: 25px;
          color: @color_nine;
          font-size: @font_size_14;
          a {
            color: @color_nine;
            text-decoration: none;
            &::after {
              content: '|';
              height: 20px;
              margin: 0 10px;
              color: @color_nine;
            }
            &:last-child {
              &::after {
                content: '';
              }
            }
          }
        }
      }
      .center {
        .center_top {
          align-items: flex-end;
          img {
            width: 48px;
            height: 48px;
            margin-right: 5px;
          }
          p {
            font-weight: bold;
            line-height: 37px;
            color: @color_ten;
            font-size: @font_size_26;
          }
        }
        span {
          line-height: 40px;
          color: @color_ten;
          font-size: @font_size_14;
          &:nth-child(2) {
            margin-top: 27px;
          }
        }
      }
      .right {
        .box {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 220px;

          &:nth-child(1) {
            margin-right: 36px;
          }
          img {
            width: 150px;
            height: 150px;
            object-fit: cover;
          }
          span {
            height: 35px;
            line-height: 35px;
            text-align: center;
            color: @color_ten;
            font-size: @font_size_16;
          }
        }
      }
    }
  }
  .bottom {
    padding: 20px 0;
    background-color: @color_eleven;
    a {
      letter-spacing: 2px;
      color: @color_twelve;
      font-size: @font_size_14;
    }
  }
}
</style>
