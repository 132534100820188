<template>
  <div>
    <el-backtop :right="114" :bottom="350" :visibility-height="height">
      <div class="backtop flex_center">
        <img src="@/assets/image/icon/04.png" />
      </div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: 99999
    }
  },

  mounted() {
    window.addEventListener('scroll', this.scrollBottom)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollBottom)
  },

  methods: {
    scrollBottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight
      let scrollHeight = document.documentElement.scrollHeight
      if (scrollTop + clientHeight >= scrollHeight) {
        this.height = scrollTop - 330
      }
    }
  },
  watch: {
    $route() {
      this.height = 9999
    }
  }
}
</script>

<style lang="less" scoped>
.backtop {
  z-index: 10;
  width: 50px;
  height: 50px;
  position: fixed;
  cursor: pointer;
  border-radius: 5px;
  background: rgba(255, 255, 255);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);

  img {
    width: 24px;
    height: 20px;
  }
}
</style>
