import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import {
  Col,
  Form,
  Main,
  Icon,
  Image,
  Aside,
  Input,
  Radio,
  Select,
  Header,
  Option,
  Dialog,
  Button,
  Upload,
  Backtop,
  Message,
  FormItem,
  Carousel,
  Checkbox,
  Statistic,
  Container,
  DatePicker,
  Popconfirm,
  Pagination,
  TimePicker,
  RadioGroup,
  CarouselItem,
  CheckboxGroup
} from 'element-ui'
const components = [
  Col,
  Form,
  Main,
  Icon,
  Image,
  Aside,
  Input,
  Radio,
  Select,
  Header,
  Option,
  Dialog,
  Button,
  Upload,
  Backtop,
  Message,
  FormItem,
  Carousel,
  Checkbox,
  Container,
  Statistic,
  DatePicker,
  Popconfirm,
  Pagination,
  TimePicker,
  RadioGroup,
  CarouselItem,
  CheckboxGroup
]

export function registerElement() {
  for (const component of components) {
    Vue.component(component.name, component)
  }
}

Vue.prototype.$message = Message
