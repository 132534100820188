<template>
  <div class="loading flex_direction_column">
    <img src="../../assets/image/loading.gif" />
    <span>loading....</span>
  </div>
</template>

<script>
export default {
  name: 'MatchWebsitePcIndex',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
.loading {
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  background-color: rgba(255, 255, 255);
}
img {
  width: 60px;
  height: 60px;
}
span {
  font-size: 28px;
  margin-top: 10px;
}
</style>
