import { service } from '../index'

// 配置信息（头部脚部数据）
export function getConfig() {
  return service.post({
    url: '/index/getconfig'
  })
}

// 首页数据
export function getHome() {
  return service.post({
    url: '/index/index'
  })
}

// 赛事 活动 banner
export function postBanner(data) {
  return service.post({
    url: '/index/banner',
    data
  })
}

// 收银台
export function matchCashier(data) {
  return service.post({
    url: '/order/matchCashier',
    data
  })
}

// 拉起支付
export function orderPay(data) {
  return service.post({
    url: '/order/orderPay',
    data
  })
}

// 支付成功验证
export function orderPayCheck(data) {
  return service.post({
    url: '/order/orderPayCheck',
    data
  })
}

// 成绩赛事列表
export function matchAchievement(data) {
  return service.post({
    url: '/index/matchAchievement',
    data
  })
}

// 成绩查询
export function achievement(data) {
  return service.post({
    url: '/index/achievement',
    data
  })
}

// 成绩查询返回结果
export function searchResult(data) {
  return service.post({
    url: '/index/searchResult',
    data
  })
}

// 成绩日期
export function getYear(data) {
  return service.post({
    url: '/index/getYear',
    data
  })
}

// 友情链接
export function friendship() {
  return service.post({
    url: '/index/friendship'
  })
}
