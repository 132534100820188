import { service } from '../index'

// 注册用户
export function postReg(data) {
  return service.post({
    url: '/login/pcReg',
    data
  })
}

// 获取验证码
export function getMobileCode(data) {
  return service.post({
    url: '/login/getMobileCode',
    data
  })
}

// 密码登录
export function postLogin(data) {
  return service.post({
    url: '/login/pcLogin',
    data
  })
}

// 验证码登录
export function postCode(data) {
  return service.post({
    url: '/login/codeLogin',
    data
  })
}

// 退出登录
export function outLogin() {
  return service.post({
    url: '/login/outLogin'
  })
}

// 修改密码
export function updatePwd(data) {
  return service.post({
    url: '/user/updatePwd',
    data
  })
}

// 获取个人信息
export function getInfo(data) {
  return service.post({
    url: '/index/getInfo',
    data
  })
}

// 个人表单数据
export function infoData(data) {
  return service.post({
    url: '/home/infoData',
    data
  })
}

// 修改个人信息
export function editInfo(data) {
  return service.post({
    url: '/home/editInfo',
    data
  })
}

// 我的活动
export function activeOrder(data) {
  return service.post({
    url: '/home/activeOrder',
    data
  })
}

// 我的赛事
export function matchOrder(data) {
  return service.post({
    url: '/home/matchOrder',
    data
  })
}

// 上传成绩
export function uploadAch(data) {
  return service.post({
    url: '/home/uploadAch',
    data
  })
}

// 我的成绩
export function achievement(data) {
  return service.post({
    url: '/home/achievement',
    data
  })
}

// 取消订单
export function orderCancel(data) {
  return service.post({
    url: '/order/cancel',
    data
  })
}

// 组委会注册
export function regClub(data) {
  return service.post({
    url: '/login/regClub',
    data
  })
}

export function checkOrder(data) {
  return service.post({
    url: '/order/checkOrder',
    data
  })
}
